import React from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import { UserOutlined, CalendarOutlined, FileTextOutlined, ShopOutlined } from '@ant-design/icons';


const UserDashboard = () => {
  return (
   <>
     <div style={{ padding: '24px', background: '#fff' }}>
      <Row gutter={16}>
        <Col span={24}>
          <Card>
            <h1>Welcome to sentinel</h1>
          </Card>
        </Col>
      
      </Row>
    </div>

   </>
  );
};

export default UserDashboard;
