import React from 'react'
import AdminRoutes from './admin'
import UserRoutes from './user'


const Routes = () => {
  const url =  window.location.host?.split(".")?.[0]
  return (
   <>
    { url !== "admin" ?<UserRoutes/> : <AdminRoutes/>}
   </>
  )
}

export default Routes