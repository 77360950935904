import React from 'react'
import { Routes, Route } from "react-router-dom";

import UserDashboard from '../../pages/user/DashBoard/Dashboard';
import UserLayout from '../../layout/user/UserLayout';
import Group from '../../pages/user/group/Group';


const UserPrivateRoutes = () => {
  return (

   <>
   <UserLayout>
    <Routes>
        <Route path="/" element={<UserDashboard/>}/>
        <Route path="/groups" element={<Group/>}/>
      
        <Route path="*" element={<UserDashboard/>}/>

    </Routes>
    </UserLayout>
   </>
   
  )
}

export default UserPrivateRoutes