import axios from "axios";

const  API_URL = 'https://api.thesentinel.app/v1/api'

function updateAuthorizationHeader() {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["authorization"] = "Bearer " + token;
  }

  export async function userLogin (params) {
    return await axios.post(API_URL + "/user/login", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function groupList(params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/group/get-listing", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

    export async function deleteGroup(params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/group/delete-group", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function addGroupResp(params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/group/response/add-new", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }